export function authHeader() {
  return {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json",
    "Content-role-reload": "Reload-is-allowed"
  };
}

export function authHeaderLang(lang) {
  return {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "Application/json",
    "lang": lang
  };
}