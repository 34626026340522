<template>
  <div class="col text-center" style="font-size: 2.5em">      
    <a v-if="socialList.facebook" :href="socialList.facebook" class="me-2 ms-1" target="_blank"
      ><i class="bi bi-facebook"></i
    ></a>
    <a v-if="socialList.instagram" :href="socialList.instagram" class="me-2 ms-1" target="_blank"
      ><i class="bi bi-instagram"></i
    ></a>
    <a v-if="socialList.youtube" :href="socialList.youtube" class="me-2 ms-1" target="_blank"
      ><i class="bi bi-youtube"></i
    ></a>
    <a v-if="socialList.linkedin" :href="socialList.linkedin" class="me-2 ms-1" target="_blank"
      ><i class="bi bi-linkedin"></i
    ></a>
    <a v-if="socialList.twitter" :href="socialList.twitter" class="me-2 ms-1" target="_blank"
      ><i class="bi bi-twitter"></i
    ></a>
  </div>
</template>

<script>
import { URL_BASE } from "../services/Commons";
import SobreService from "../services/sobre.service";
export default {
  name: "SocialComponent",
  props: [],
  data: () => ({
    socialList: [],
    urlBase: "",
  }),
  mounted() {
    this.urlBase = URL_BASE;

    SobreService.getOne().then((result) => {      
      if (result.status < 400) {
        this.socialList = result.data;
      }
    });
  },
};
</script>