import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/home/Home.vue'),
    },
    {
        path: '/home',
        name: 'Home1',
        component: () =>
            import ('../views/home/Home.vue'),
    },
    {
        path: '/sobre',
        name: 'Sobre',
        component: () =>
            import ('../views/about/Sobre.vue'),
    },
    {
        path: '/ler/:layout/:id',
        name: 'Leitura',
        component: () =>
            import ('../views/ler/Ler.vue'),
    },
    {
        path: '/category/:id',
        name: 'Listar',
        component: () =>
            import ('../views/category/Listar.vue'),
    },
    {
        path: '/artigos',
        name: 'Artigos',
        component: () =>
            import ('../views/articles/Listar.vue'),
    },
    {
        path: '/notas',
        name: 'Notas',
        component: () =>
            import ('../views/notes/Listar.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router