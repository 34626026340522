<template>
  <div class="footer">
    <footer class="blog-footer mt-5">
      <small>
        Desenvolvido com o
        <i
          class="bi bi-heart-fill"
          style="color: #ff0000"
          aria-hidden="true"
          title="Coração"
        ></i>
        por
        <a href="http://vrclic.com.br/" target="_blank"
          ><b>VRCLIC - Soluções Digitais</b></a
        ></small
      >
    </footer>
  </div>
</template>

<script>
import SobreService from "../services/sobre.service";
export default {
  name: "Footer",
  data: () => ({
    aboutItem: [],
  }),
  mounted() {
    SobreService.getOne().then((result) => {
      if (result.status < 400) {
        this.aboutItem = result.data;
      }
    });
  },
};
</script>
<style>
.cls-1 {
  fill: #d12e29;
}
</style>