import axios from "axios";
import { authHeader } from "./auth-header";

import { URL_API } from "./Commons";

class ArtigoService {
  async getAll() {
    return await axios.get(URL_API + "artigos", { headers: authHeader() });
  }

  async getPaginacao(quant = 10) {
    return await axios.get(URL_API + "artigotake/0/" + quant, {
      headers: authHeader(),
    });
  }

  async getOne(id) {
    return axios.get(URL_API + "artigo/" + id, { headers: authHeader() });
  }

  async search(word) {
    return await axios.post(
      URL_API + "artigosearch",
      { search: word },
      { headers: authHeader() }
    );
  }
}

export default new ArtigoService();
