<template>
  <div class="topmenu">
    <header
      class="blog-header py-3 rounded-bottom"
      style="background-color: #a5c3d1"
    >
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container col-sm-12 col-md-12 col-lg-12">
          <div class="col-sm-12 col-md-6 col-md-6">
            <a class="navbar-brand" href="/">
              <img
                src="../assets/images/horiz_logo.png"
                class="img-fluid"
                title="Instituto de Desenvolvimento Econômico e Integração Autossustentável de Santa Helena"
                alt="Instituto de Desenvolvimento Econômico e Integração Autossustentável de Santa Helena"
              />
            </a>
          </div>

          <div
            class="
              col-sm-12 col-md-6 col-lg-6
              collapse
              navbar-collapse
              justify-content-end
              align-items-center
            "
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav mb-2 mt-2">
              <li class="nav-item mb-2">
                <a class="p-2 link-secondary" href="/">Home</a>
              </li>

              <li class="nav-item mb-2">
                <a class="p-2 link-secondary" href="/sobre">Quem Somos</a>
              </li>

              <li class="nav-item mb-2">
                <a
                  class="p-2 link-secondary"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#contato"
                  >Contato</a
                >
              </li>
              <li class="nav-item mb-2">
                <a
                  class="p-2 link-secondary"
                  href="#"
                  aria-label="Search"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="mx-3"
                    role="img"
                    viewBox="0 0 24 24"
                  >
                    <title>Buscar</title>
                    <circle cx="10.5" cy="10.5" r="7.5" />
                    <path d="M21 21l-5.2-5.2" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <button
            class="navbar-toggler mt-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">O que está Procurando?</h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="floatingInput"
              placeholder="Sua busca..."
              v-model="searchWord"
              @change="goSearch"
            />
            <label for="floatingInput">Sua busca...</label>
          </div>    
          <div class="row">            
            <div class="row ms-1 border-bottom mb-4" v-if="searchEstudo.length > 0">
              <h5 class="bg-primary p-2 text-white rounded">
                Santa Helena em Números
              </h5>
              <div class="list-group mb-2" v-for="buscaEstudos in searchEstudo" :key="buscaEstudos.id">
                <a :href="'/ler/estudo/' + buscaEstudos.id" class="list-group-item list-group-item-action">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{buscaEstudos.titulo}}</h5>
                    <small v-if="moment">{{ moment(buscaEstudos.updated_at).format("DD/MM/YY") }}</small>
                  </div>
                  <!-- <p class="mb-1">Some placeholder content in a paragraph.</p> -->
                  <small>{{buscaEstudos.subtitulo}}</small>
                </a>
              </div>              
            </div>           

            <div class="row ms-1 border-bottom mb-4" v-if="searchArtigo.length > 0">
              <h5 class="bg-primary p-2 text-white rounded">
                Observatório Social
              </h5>
              <div class="list-group mb-2" v-for="buscaArtigos in searchArtigo" :key="buscaArtigos.id">
                <a :href="'/ler/artigo/' + buscaArtigos.id" class="list-group-item list-group-item-action">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{buscaArtigos.titulo}}</h5>
                    <small v-if="moment">{{ moment(buscaArtigos.created_at).format("DD/MM/YY") }}</small>
                  </div>
                  <!-- <p class="mb-1">Some placeholder content in a paragraph.</p> -->
                  <small>{{buscaArtigos.subtitulo}}</small>
                </a>
              </div>   
            </div>           

            <div class="row ms-1 border-bottom mb-4" v-if="searchNotas.length > 0">
              <h5 class="bg-primary p-2 text-white rounded">Destaques</h5>
              <div class="list-group mb-2" v-for="buscaNotas in searchNotas" :key="buscaNotas.id">
                <a :href="'/ler/nota/' + buscaNotas.id" class="list-group-item list-group-item-action">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{buscaNotas.titulo}}</h5>
                    <small v-if="moment">{{ moment(buscaNotas.created_at).format("DD/MM/YY") }}</small>
                  </div>
                  <!-- <p class="mb-1">Some placeholder content in a paragraph.</p> -->
                  <small>{{buscaNotas.subtitulo}}</small>
                </a>
              </div>   
            </div>
          </div>          

          <div class="text-center">
            <small
              >Se não encontrou o que buscava?
              <a href="#" data-bs-toggle="modal" data-bs-target="#contato"
                >Fale conosco</a
              >.</small
            >
            <br />
            <img
              src="../assets/images/logo.png"
              class="img-fluid mt-5"
              style="max-width: 20%"
              title="Instituto de Desenvolvimento Econômico e Integração Autossustentável de Santa Helena"
              alt="Instituto de Desenvolvimento Econômico e Integração Autossustentável de Santa Helena"
            />
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="contato"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-primary text-white">
              <h5 class="modal-title" id="exampleModalLabel">
                <i class="bi bi-chat-right-dots"></i> Fale Conosco.
              </h5>
              <a
                type="button"
                class="text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                ><i class="bi bi-x-lg"></i
              ></a>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 text-center">
                  <i class="bi bi-envelope" style="font-size: 2em"></i>
                  <h6 style="font-size: 1.2em">
                    <a
                      href="mailto:contato@institutoideias-sh.com.br"
                      target="_blank"
                      title="Enviar email para contato@institutoideias-sh.com.br"
                      >{{ aboutItem.email }}</a
                    >
                  </h6>
                </div>
                <div class="col-12 text-center mt-4">
                  <div class="row">
                    <div class="col-6">
                      <i class="bi bi-whatsapp" style="font-size: 2em"></i>
                      <h6 style="font-size: 1.2em">
                        <a
                          href="https://api.whatsapp.com/send?phone=5545988023107"
                          target="_blank"
                          title="Abrir Whatsapp"
                          ><b>{{ aboutItem.whatsapp }}</b></a
                        >
                      </h6>
                    </div>
                    <div class="col-6">
                      <i class="bi bi-telephone" style="font-size: 2em"></i>
                      <h6 style="font-size: 1.2em">
                        <a href="#" target="_blank" title="Abrir Whatsapp"
                          ><b>{{ aboutItem.telefone }}</b></a
                        >
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-4 mb-3 mt-3">
                <h4 class="fst-italic text-center">Nossas Redes.</h4>
                <div class="row">
                  <social-component></social-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import SobreService from "../services/sobre.service";
import ArtigoService from "../services/artigo.service";
import EstudoService from "../services/estudo.service";
import NotaDiariaService from "../services/notadiaria.service";

import socialComponent from "../components/socialComponent.vue";
import { getCurrentInstance } from "vue";
import moment from 'moment'
export default {
  name: "TopMenu",
  props: {
    msg: String,
  },

  data() {
    return {
      aboutItem: [],
      app: null,
      searchWord: "",
      searchEstudo: [],
      searchNotas:[],
      searchArtigo:[],
          moment:null,

    };
  },

  mounted() {

    this.moment = moment;
    this.moment.locale('pt-br');

    this.app = getCurrentInstance();
    this.reload();
  },
  components: {
    socialComponent,
  },
  methods: {
    goSearch: function(){
        if(this.searchWord.length > 4){
            ArtigoService.search(this.searchWord).then((result) => {
            this.searchArtigo = result.data; 
            });
            NotaDiariaService.search(this.searchWord).then((result) => {
            this.searchNotas = result.data; 
            });
            EstudoService.search(this.searchWord).then((result) => {
            this.searchEstudo = result.data; 
            });
        }else{
            this.searchArtigo = [];
            this.searchNotas = [];
            this.searchEstudo = [];
        }
    },
    reload: function () {
      SobreService.getOne().then((result) => {
        if (result.status < 400) {
          this.aboutItem = result.data;
        }
      });
    },
  },
};
</script>
