import axios from 'axios';
import {authHeader} from './auth-header';

import { URL_API } from './Commons';

class SobreService {

  getOne() {
    return axios.get(URL_API + 'sobreone', { headers: authHeader() });
  }


}

export default new SobreService();