import axios from "axios";
import { authHeader } from "./auth-header";

import { URL_API } from "./Commons";

class EstudoService {
  async getAll() {
    return await axios.get(URL_API + "estudos", { headers: authHeader() });
  }

  async getPaginaCategoria(catid, iniquant, quant) {
    return await axios.get(
      URL_API + "estudocategoria/" + catid + "/" + iniquant + "/" + quant,
      { headers: authHeader() }
    );
  }

  async getPaginacao(quant = 10) {
    return await axios.get(URL_API + "estudotake/0/" + quant, {
      headers: authHeader(),
    });
  }

  async getOne(id) {
    return axios.get(URL_API + "estudo/" + id, { headers: authHeader() });
  }

  async search(word) {
    return await axios.post(
      URL_API + "estudosearch",
      { search: word },
      { headers: authHeader() }
    );
  }
}

export default new EstudoService();
